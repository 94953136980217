.about__container {
  background-color: #fff;
  padding: 0;
}

.about__content {
  max-width: 600px;
  margin: 0 auto;
  padding: 1em;
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.about__left {
  flex: 0.75;
  padding: 1em 0;
}

.about__right {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex: 0.1;

  p {
    font-family: "VanillaBacked";
    position: absolute;
    right: -50px;
    top: -15px;
    z-index: 1;
    font-size: 27px;
    max-width: 190px;
    line-height: 1.2;
  }
}
 
img.about__arrow {
  width: 85px;
  position: absolute;
  right: -65px;
  bottom: 190px; 
  transform: scaleX(-1) rotate(30deg);
  z-index: 1;
}

img.about__hopper {
  width: 225px;
  position: absolute;
  right: -120px;
  bottom: 0;
}

@media screen and (min-width: 300px) {
  .about__container {
    padding: 0 2em;
  }

  .about__content {
    flex-direction: column;
    padding: 0;
  }

  .about__right {
    display: block;

    p {
      position: relative;
      right: 70px;
    }
  }

  img.about__arrow {
    position: relative;
    right: 50px;
    bottom: 20px;
    transform: scaleX(1) rotate(-30deg);
  }

  img.about__hopper {
    right: -10%;
  }
}

@media screen and (min-width: 500px) {
  img.about__hopper {
    width: 225px;
    right: 10%;
  }
}

@media screen and (min-width: 768px) {
  .about__content {
    flex-direction: row;
    padding: 1em 0;
  }

  .about__right {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex: 0.1;
  
    p {
      position: absolute;
      right: -20px;
      top: -5px;
      z-index: 1;
    }
  }
   
  img.about__arrow {
    width: 85px;
    position: absolute;
    right: -55px;
    bottom: 180px; 
    transform: scaleX(-1) rotate(30deg);
    z-index: 1;
  }
  
  img.about__hopper {
    width: 255px;
    position: absolute;
    right: -120px;
    bottom: 0;
  }
}