img {
  width: 90%;
  border-radius: 5px;
}

.modal__header--images {
  margin: 0;
  max-height: 50px;
  width: 90%;
}

.modal__content--images {
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 100%;
  margin: 0 auto;
}

@media screen and (min-width: 300px) {
  .modal__header--images {
    margin: 0;
    max-height: 50px;
    width: 100%;
  }

  img {
    width: 100%;
    border-radius: 5px;
  }
}
