.header__container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  flex: 1 100%;
  z-index: 0;
  position: relative;
  background-image: url('../assets/img/bg-palms-original.jpg'), linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5));
  //background-color: var(--dark-blue);
  background-blend-mode: overlay;
  background-size: cover;
  background-position: 0 60%;
  background-repeat: no-repeat;
}

@media screen and (min-width: 300px) {
  .header__container {
    background-position: 8% 0;
  }
}

@media screen and (min-width: 400px) {
  .header__container {
    background-position: 5% 20%;
  }
}

.header__h1, .header__typed-element {
  color: var(--white);
  margin: 0;
  font-size: 36px;
}

.header__h1--light-blue {
  color: var(--light-blue)
}

#typedElementIntroName, #typedElementDescDev {
  margin-left: 8px;
}

#typedElementIntroName {
  color: var(--light-blue);
}

.header__intro {
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
}

.header__btn {
  margin: 1em auto 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
}

.btn {
  font-family: inherit;
  font-size: 24px;
  border: none;
  padding: 0.5em 1em;
  border-radius: 5px;
  cursor: pointer;
}

.btn--hide {
  opacity: 0;
}

.btn--show {
  opacity: 1;
  transition: opacity 0.3s ease-in-out;
}