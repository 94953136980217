@keyframes moveDown {
  from {
    transform: translateY(-5rem);
  }
  to {
    transform: translateY(0rem);
  }
}

.navigation__container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 50px;
  transition: height 0.3s ease-in-out;
  padding: 0 1em;
  background-color: var(--dark-blue);
  z-index: 999;
  flex: 1;

  h2 {
    color: var(--white);
    margin: 0;  
  }

}

.navigation__container {
  //position: fixed;
  top: 0;
  left: 0;
  right: 0;
  //animation: moveDown 0.3s ease-in-out;  
  height: 75px;
}

.navigation__container--sticky {
  height: 50px;
  background-color: var(--dark-blue) !important;

  .links__container--open {
    top: 50px;

    button {
      height: 60px;
      padding: 0.5em 1em;
      color: #fff;
    }
  
    .btn--contact {
      border: 2px solid #fff;
      border-radius: 5px;
      
      &:hover {
        background-color: #fff;
        transition: background-color 0.2s ease-in-out;
      }
    }
  }
}

.navigation__links {
  display: flex;
}

.navigation__toggle {
  color: #fff;
}

.links__container {
  flex: 0.4;
  display: flex;
  justify-content: space-between;

  button {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .btn--contact {
    border: 2px solid #fff;
    border-radius: 5px;

    &:hover {
      background-color: #fff;
      transition: background-color 0.2s ease-in-out;
    }
  }

  button {
    background-color: transparent;
    color: var(--white);
    font-size: 18px;
    font-family: inherit;
    border: none;
    cursor: pointer;
    height: 35px;
    display: flex;
    align-items: center;
    justify-content: center;

    &:hover {
      color: var(--light-blue)
    }
  }
}

.navigation__toggle {
  flex: 0.1;
  display: flex;
  
  font-size: 28px;
  margin: 0;
  cursor: pointer;
  align-items: center;
  justify-content: flex-end;
  padding-right: 1rem;
}

/* Small screens */
@media screen and (min-width: 300px) {
  .navigation__container {
    display: flex;
    flex: 1;
    position: fixed;
    top: 0;
    left: 0;
    background-color: transparent;
  }

  .navigation__toggle {
    flex: 0.1;
    display: flex;
    
    font-size: 28px;
    margin: 0;
    cursor: pointer;
    align-items: center;
    justify-content: flex-end;
    padding-right: 1rem;
  }

  .links__buttons {
    display: flex;
    flex-direction: column;
    position: relative;
    top: 50px;
  }

  .links__container--open {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
    top: 75px;
    left: 0;
    position: absolute;
    background-color: var(--dark-blue);
    visibility: visible;
    z-index: 9999;
    height: calc(100vh - 50px);
    width: 100%;
    transition-property: height;
    -moz-transition-duration: 0.7s;
      -webkit-transition-duration: 0.7s;
      -o-transition-duration: 0.7s;
      transition-duration: 0.7s;
      -moz-transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
      -webkit-transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
      -o-transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
      transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
    margin: 0 auto 2rem auto;
    padding: 1em 0 0 0;
    overflow-y: hidden;

    button {
      margin: 1em auto;
      font-size: 27px;
      height: 60px;
      padding: 0.5em 1em;
    }
  }

  .links__container--closed {
    visibility: hidden;
    display: flex;
    flex-direction: column;
    height: 0;

    button {
      padding: 0 0.5em;
      margin: 0 1em;
    }
  }
}

@media screen and (min-width: 783px) {
  .navigation__container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 50px;
    transition: height 0.3s ease-in-out;
    padding: 0 1em;
    background-color: transparent;
    z-index: 999;
    flex: 1;
  
    h2 {
      color: var(--white);
      margin: 0;  
    }
  
  }
  
  .navigation__container {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    //animation: moveDown 0.3s ease-in-out;  
    height: 50px;
  }
  
  .navigation__links {
    display: flex;
  }

  .navigation__toggle {
    display: none;
  }

  .links__buttons {
    display: flex;
    flex-direction: row;
    position: relative;
    top: 0;
  }

  .links__container--closed, .links__container--open, .links__container {
    flex: 0.4;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    height: 50px;
    visibility: visible;

    button {
      background-color: transparent;
      color: var(--white);
      font-size: 18px;
      font-family: inherit;
      border: none;
      cursor: pointer;
     
      &:hover {
        color: var(--light-blue)
      }
    }
  }
}