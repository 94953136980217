.projects__container {
  background-color: var(--light-grey);
}

.projects__content {
  max-width: 600px;
  margin: 0 auto;
}

.projects__list {
  display: flex;
  flex-direction: column;
}

.list__item {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
  margin: 1em auto;
  width: 100%;

  h4 {
    margin: 0;
  }

  p {
    margin: 0.5em 0;
    text-align: center;
  }

  h4 {
    color: var(--blue);
  }
}

.item__links {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 0.5em;

  svg {
    font-size: 24px;
    margin: 0 0.2em;
  }

  a {
    color: var(--light-blue);
    display: flex;
    align-items: center;

    &:hover {
      color: var(--blue);
    }
  }
}

.separator {
  margin: 0 0.25em;
}

.tooltip {
  position: relative;
  transition: 250ms ease;
  cursor: default;
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    color: var(--grey);

    .tooltip__content {
      opacity: 1;
      transform: translateX(-50%) translateY(-100%);
    }
  }

  .tooltip__content {
    opacity: 0;
    pointer-events: none;
    transform: translateX(-50%) translateY(-80%);

    width: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
    white-space: nowrap;
    position: absolute;
    left: 50%;
    top: -25%;
    transform: translateX(-50%) translateY(-100%);
    color: #fff;
    padding: 0.5rem 1rem;
    height: auto;
    background-color: var(--blue);
    border: 1xp solid var(--blue);
    border-radius: 5px;
    transition: 250ms ease;
    font-size: 1rem;

    &:hover {
      color: #ffffff;
    }
  
    &:after {
      content: '';
      position: absolute;
      width: 20px;
      height: 50px;
      top: 99%;
      left: 40%; 
      background: var(--blue); 
      -webkit-clip-path: polygon(0% 0%, 100% 0%, 50% 10%);
      clip-path: polygon(0% 0%, 100% 0%, 50% 10%);
    }
  }
}

.list__image-container {
  width: 100%;
  display: flex;
  flex-wrap: wrap;

  img {
    flex: 1;
    min-width: 250px;
    width: 35%;
    display: flex;
    align-items: center;
    justify-content: center;
    align-self: flex-start;
    background-color: red;
    margin: 0.5em;
    border-radius: 5px;
    cursor: pointer;
  }
}

@media screen and (min-width: 300px) {
  .projects__container {
    padding: 1em 2em;
  }
}