.popup__container {
  position: initial !important;
  
  .modal {
    display: flex;
    flex-direction: column;
  }
}

.modal__header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  flex: 1 0;
  width: 100%;
  margin-bottom: 1rem;

  h3 {
    margin-top: 0;
    margin-bottom: 0;
  }
  
  button {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    flex: 0;
    border: none;
    color: var(--red);
    font-size: 28px;
    cursor: pointer;
    position: absolute;
    top: 20px;
    right: 20px;
  }
}

.modal__content {
  flex: 1;
  width: 100%;
  max-width: 500px;
  margin: 0 auto;
}

form {
  display: flex;
  align-items: center ;
  justify-content: center;
  flex-direction: column;
  max-width: 350px;
  margin: 0 auto;
  
  p {
    margin: 0;
  }
}

input[type=text], textarea {
  font-family: "Barlow-Regular";
  font-size: 1rem;
  padding: 0.5rem 0.75rem;
  border-radius: 5px;
  flex: 1;
  border: 2px solid var(--grey);
  margin-bottom: 1em;
  width: 100%;

  &:focus {
    border: 2px solid var(--primary-color);
  }
}

textarea {
  height: 75px;
}

input[type=submit]{
  font-size: 21px;
  min-width: 300px;
  width: 100%;
  flex: 1;

  &:disabled, &:disabled:hover {
    background-color: #cccccc;
    color: #999999;
    pointer-events: none;
  }
}

.send-success {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 1em;
  color: #118243;
  margin-bottom: 2em;

  p {
    margin: 0;
  }

  svg {
    font-size: 72px;
    color: #118243;
    margin-bottom: 0.1em;
  }
}

.send-error {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 1em;
  border: 3px solid #a31c2a;
  border-radius: 5px;
  background-color: #e09da4;
  color: #a31c2a;
  margin-bottom: 2em;

  p {
    margin: 0;
  }
}