/* Fonts */
@font-face {
  font-family: "Barlow-Bold";
  src: url("./assets/fonts/barlow/Barlow-Bold.otf") format("opentype"); 
}

@font-face {
  font-family: "Barlow-Regular";
  src: url("./assets/fonts/barlow/Barlow-Regular.otf") format("opentype"); 
}

@font-face {
  font-family: "VanillaBacked";
  src: url("./assets/fonts/vanilla_backed/VanillaBacked.ttf") format("opentype"); 
}

/* Color Variables */
:root {
  --primary-color: #333333;
  --secondary-color: #999999;
  --highlight: #B4E55C;
  --overlay-color: rgba(0, 0, 0, 0.8);
  --blue: #458296;
  --dark-blue: #2C393F;
  --light-blue: #6eaec4;
  --light-grey: #dce3e6;
  
  /*--blue: rgb(25, 68, 89);*/ 
  --red: rgb(239, 89, 57);
  --dark-red: rgb(186, 52, 22); 
  --green: rgb(17, 201, 59);
  --dark-green: rgb(15,128,41);
  --grey: #abb0b3;
  --light-grey: #e3e9ee;
  --dark-grey: #7c8082;
  --background-grey: rgb(240, 240, 240);
  --white: #ffffff;
}

* {
  box-sizing: border-box;
}

html {
  font-family: sans-serif;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  position: relative;
  height: 100%;
  font-size: 16px;
}

body {
  font-family: "Barlow-Regular"; 
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #F0F0F0;
  font-weight: 100;
  line-height: 1.6;
  color: #333;
  font-size: 1.1rem;
  margin: 0;
  height: 100%;
  overflow-x: hidden;
}

#root, .container {
  height: 100%;
}

h1, h2, h3, h4 {
  font-family: 'Barlow-Regular';
  line-height: 1.3;
}

strong {
  font-family: 'Barlow-Bold';
}

button, input[type=submit] {
  -webkit-appearance: none;
}

button:focus, input:focus, a:focus, textarea:focus {
  outline: none;
}

/* Buttons */
.btn--blue {
  background-color: var(--light-blue);
  color: #fff;
}

.btn--blue:hover {
  background-color: var(--blue);
  transition: background-color 0.2s ease-in-out;
}