.layout__container {
  height: 100%;
}

/* Popup */
.popup {
  z-index: 999999;
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.75);
  overflow: hidden;

  &.showModal{
    visibility: visible;
    opacity: 1;
    transition: all 300ms ease-in-out;
  }

  &.hideModal{
    visibility: hidden;
    opacity: 0;
    transition: all 300ms ease-in-out;
  }
}

.popup__container--images {
  background-color: transparent;
  max-width: 90%;

  div button {
    background-color: transparent;
  }
}

.popup__container--contact {
  background-color: #F0F0F0;
  max-width: 400px;
}

.popup__container {
  position: absolute;
  margin: 0 auto;
  padding: 2rem;
  border-radius: 15px;
  max-height: 85%;
  overflow-y: auto;
  flex-direction: column;

  transition: transform 300ms ease-in-out;
  transform: translateY(-300px);

  &.slideIn {
    transform: translateY(50px);
  }

  &.slideOut {
    transform: translateY(-300px);
  }
}

.popup__container > div {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;

  button {
    flex: 0.475;
  }
}

@media screen and (min-width: 300px) {
  .popup__container--contact {
    max-width: 350px;
    width: 90%;
  }

  .popup__container--images {
    position: absolute;
    margin: 0 auto;
    padding: 2rem 0;
    border-radius: 15px;
    max-height: 85%;
    overflow-y: auto;
    flex-direction: column;
    
    //min-width: 400px;
    transition: transform 300ms ease-in-out;
    transform: translateY(-300px);
  }
}

@media screen and (min-width: 768px) {
  .popup__container--contact {
    max-width: 400px;
  }

  .popup__container--images {
    position: absolute;
    margin: 0 auto;
    padding: 2rem;
    border-radius: 15px;
    max-height: 85%;
    overflow-y: auto;
    flex-direction: column;
    
    min-width: 400px;
    max-width: 1200px;
    transition: transform 300ms ease-in-out;
    transform: translateY(-300px);
  }
}