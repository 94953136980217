.footer__container {
  height: 50px;
  background-color: var(--dark-blue);
  display: flex;
  align-items: center;
  justify-content: center;
}

.footer__text {
  color: #fff;
}